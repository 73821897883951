.page-home {
  .call-to-action {
    text-align: center;
    margin: 2em 0;

    p {
      color: #557124;
      font-weight: 300;
    }

    #action-signup {
      width: 200px;
    }
  }


  // Testimonials
  // ------------

  #testimonials {
  }

  blockquote {
    // reset Bootstrap
    padding: 0;
    margin: 0;
    border-left: 0px;

    position: relative;
    overflow: hidden;
    padding-left: 100px;
    color: #333;
    // height: 120px;

    h2 {
      margin: 5px 0;
      font-weight: 300;
      font-size: 20px;
      line-height: 130%;
    }

    small {
      display: block;
      color: #999;
      font-size: 15px;
    }

    small:before {
      content: '\2014';
    }

    a {
      color: #008ee8;
    }

    .open {
      position: absolute;
      top: 50px;
      left: 5px;
      color: #333;
      font-weight: bold;
      font-size: 160px;
    }
  }
}

// main#pricing
// ------------

.page-pricing {
  .call-to-action {
    margin: 1em 0;
    text-align: center;

    // reset Bootstrap style
    .btn {
      vertical-align: baseline;
    }
  }

  .plan {
    margin: 20px 0 25px 0;

    .name {
      display: inline;
      float: left;
      width: 220px;

      h2 {
        margin-bottom: 0;
      }
    }

    .description {
      display: inline;
      float: left;
      width: 700px;
    }
  }

  .plan table {
    float: left;
    margin: 15px 0;
    width: 100%;

    th {
      width: 200px;
      border-right: 2px solid #393939;
      text-align: right;
      font-weight: bold;
      font-size: 80%;
      padding-right: 15px
    }

    td {
      border: 1px solid #eaeaea;
      text-align: center;
    }

    th, td {
      vertical-align: middle;
    }

    .top, .bot {
      td {
        font-size: 90%;
      }
    }

    .mid {
      td {
        color: #557124;
        font-weight: normal;
        font-size: 125%;
      }

      td.custom {
        width: 120px;

        // reset
        color: #333;
        font-weight: 300;
        font-size: 100%;
      }
    }
  }
}