// Template.scss
// Contains the basic template styles.
// -----------------------------------------------------------------------------

// VARIABLES
// ---------

// @linkColor: #0066aa;

// @baseFontSize: 15px;
// @baseLineHeight: 24px;


// TEMPLATE
// --------

hr {
  border-top: 1px solid #ddd; // #e1e1e8
}

#header {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;

  #logo {
    a {
      display: block;
      padding-left: 80px;
      font-size: 60px;
      line-height: 120%;

      background: transparent url("/assets/images/robowhois-icon.png") no-repeat;
      color: #111111;
      text-shadow: #ccc 1px 1px 0px;
      letter-spacing: -.05em;
      font-weight: bold;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    a,
    a:hover {
      color: #111111;
      text-decoration: none;

      small {
        margin-left: -5px;
        color: #999;
        text-shadow: none;
        font-size: 35px;
        font-family: Georgia, "Bitstream Vera Serif", Times, serif;
      }
    }
  }

  #nav {
    ul {
      margin-top: 30px;
      list-style-type: none;

      text-align: right;
      text-transform: lowercase;
      font-variant: small-caps;
      font-size: 18px;

      li {
        display: inline;
        padding: 0 0 0 1.25em;

        &.current a,
        &.current a:hover {
          border-bottom: 2px solid #999;
          color: #999;
        }
      }

      a {
        padding-bottom: 2px;
        border-bottom: 2px solid transparent;

        &:hover {
          border-bottom: 2px solid #06a;
          text-decoration: none;
        }

      }
    }
  }
}


#footer {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px 0;
  border-top: 1px solid #ddd;
  text-align: center;

  p {
    font-size: 13px;
    margin: 0;
  }
}

#content {
  margin-top: 30px;
  margin-bottom: 30px;
}


#sidebar {
  h2 {
    margin: 10px 0 5px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    font-size: 17px;
    line-height: 18px;
    font-weith: bold;
  }

  ul {
    margin-left: 0;

    li {
      margin-left: 0;
      list-style-type: none;

      a {
        padding: 3px 0;
        display: block;
        cursor: pointer;

        &:hover {
          background: #f9f9f9;
          text-decoration: none;
        }
      }
    }
  }
}


// Typography.scss
// Headings, body text, lists, code, and more.
// -----------------------------------------------------------------------------

// MISCELLANEOUS
// -------------

body {
  font-family: "Helvetica Neue", Helvetica, Arial, "Liberation Sans", FreeSans, sans-serif;
  font-weight: 300;
  // -webkit-font-smoothing: antialiased;
}

h1 {
  margin-bottom: 0.75em;
  font-size: 34px;
}


// CODE
// ----

code, pre, tt {
  font-size: 13px;
  font-family: Consolas, Menlo, "Liberation Mono", Courier, monospace;
  line-height: 19px;
}
