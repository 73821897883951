//
// Application Template
// --------------------------------------------------

h2 {
  font-size: 28px;
}

h3 {
  font-size: 20px;
}

#logo {
  a {
    padding-left: 80px;
    background: transparent url("/assets/images/robowhois-icon.png") no-repeat;
  }
}


pre.headers {
  margin-bottom: 0;
  border-bottom: 0;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  background-color: #f1f1f1;
  background-image: -moz-linear-gradient(top, #f1f1f1, #e1e1e1);
  background-image: -ms-linear-gradient(top, #f1f1f1, #e1e1e1);
  background-image: -o-linear-gradient(top, #f1f1f1, #e1e1e1);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f1f1f1), to(#e1e1e1));
  background-image: -webkit-linear-gradient(top, #f1f1f1, #e1e1e1);
  background-image: linear-gradient(top, #f1f1f1, #e1e1e1);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#f1f1f1', EndColorStr='#e1e1e1');
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
}

pre.headers + pre {
  margin-bottom: 0;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}


// Code
// ---------------

// Set max-height to the code block.
pre code {
  max-height: 250px;
  overflow: auto;
  display: block;
}


// Miscellaneous
// -------------

// Fix for GitHub Gist
.gist,
.gist .line-data pre,
.gist .line-numbers {
  font-size: 13px;
  line-height: 1.4;
}


// Markers
// ---------------

.marker {
  margin: 20px 0;
  padding: 15px;
  border-left: 3px solid #eee;
}

.marker :first-child {
  margin-top: 0;
}

.marker p:last-child {
  margin-bottom: 0;
}

.marker-note {
  border-color: #ddd;
  background-color: #fdf7f7;
}

.marker-info {
  border-color: #5bc0de;
  background-color: #f4f8fa;
}

.marker-warning {
  border-color: #f0ad4e;
  background-color: #fcf8f2;
}

.marker-danger {
  border-color: #d9534f;
  background-color: #fdf7f7;
}
